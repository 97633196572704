import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import { Col, Image, Row } from "react-bootstrap"
import ReactPlayer from "react-player"
import ProgressiveImage from "react-progressive-image"
import close from "../images/close.png"

const ProjectTemplate = props => {
  //console.log(props)
  const data = props.data
  const location = props.location
  //console.log(data.cockpitProjects)
  const post = data.cockpitProjects
  const types = data.allCockpitType.edges
  const client = data.cockpitClient
  console.log(props)
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data
  const mediaUrl = process.env.REACT_APP_UPLOADS_URL
  const transition = {
    duration: 0.6,
    ease: [0.43, 0.13, 0.23, 0.96],
    when: "afterChildren",
  }
  const lang = props.pageContext.lang
  const containerVariants = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: { transition },
    },
    exit: {
      //filter: `invert()`,
      opacity: 0,
      y: 50,
      transition: { ...transition, delay: 0.4 },
    },
  }
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [props.location])
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.title.value}
        description={post.histoire.value
          .slice(0, 150)
          .replace(/<\/?[^>]+(>|$)/g, "")}
        lang={props.pageContext.lang}
      />
      <div className="blog-post" itemScope itemType="http://schema.org/Article">
        <motion.div
          classname="project"
          key={post.title.slug}
          variants={containerVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
          transition={transition}
        >
          <Row></Row>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={11} className="pl-0">
                  <motion.h1 className="project-title" initial={{ opacity: 1 }}>
                    <motion.span
                      initial={{ opacity: 0, y: 20 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { ...transition, delay: 0.1 },
                      }}
                      exit={{
                        opacity: 0,
                        y: 20,
                        transition: { ...transition, delay: 0.3 },
                      }}
                      className="title-item big-item"
                    >
                      {client.nom.value}
                    </motion.span>
                    <motion.span
                      initial={{ opacity: 0, y: 20 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { ...transition, delay: 0.2 },
                      }}
                      exit={{
                        opacity: 0,
                        y: 20,
                        transition: { ...transition, delay: 0.2 },
                      }}
                      className="title-item big-item"
                    >
                      {post.title.value}
                    </motion.span>
                    {/* <motion.span
                      initial={{ opacity: 0, y: 20 }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: { ...transition, delay: 0.3 },
                      }}
                      exit={{
                        opacity: 0,
                        y: 20,
                        transition: { ...transition, delay: 0.1 },
                      }}
                      className="title-item small-item"
                    >
                      {types.map(t => {
                        console.log(t.node.id)
                        if (
                          "Cockpit__Type__" +
                            post.type.value[0]._id +
                            "_" +
                            props.pageContext.lang ===
                          t.node.id
                        ) {
                          return t.node.type.value
                        }
                      })}
                      {/* {post.type.value[0]._id}
                    </motion.span> */}
                  </motion.h1>
                </Col>
                <Col xs={1}>
                  <Link
                    to={lang === "fr" ? "/" : "/" + lang + "/"}
                    style={{
                      position: "fixed",
                      top: "4rem",
                      right: "3rem",
                      zIndex: "99",
                    }}
                    state={{
                      currentType:
                        location.state && location.state.currentType
                          ? location.state.currentType
                          : null,
                      thePosition:
                        location.state && location.state.thePosition
                          ? location.state.thePosition
                          : null,
                    }}
                  >
                    <motion.img
                      src={close}
                      alt="close"
                      style={{ width: "30px", height: "30px" }}
                      className="center-text"
                      animate={{ y: 0, rotate: 0 }}
                      transition={transition}
                      initial={{
                        rotate: 0,
                        y: 0,
                      }}
                      exit={{
                        rotate: 180,
                        opacity: 0,
                      }}
                    />
                  </Link>
                </Col>
              </Row>
              <Row>
                <motion.h2
                  className="project-subtitle"
                  initial={{ opacity: 1 }}
                >
                  {post.subtitle.value}
                </motion.h2>
                <motion.div
                  className="project-content"
                  initial={{ opacity: 1 }}
                >
                  <h3 className="project-content-title">
                    {lang === "fr" ? "Histoire :" : "Story:"}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.histoire.value,
                    }}
                  ></div>
                  <h3 className="project-content-title">
                    {lang === "fr" ? "Réalisation :" : "Making-of:"}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.realisation.value,
                    }}
                  ></div>
                  <h3 className="project-content-title">
                    {lang === "fr" ? "Équipe :" : "Team:"}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.equipe.value,
                    }}
                  ></div>
                </motion.div>
              </Row>
            </Col>
          </Row>
          <Row>
            {post.visuels.value &&
              post.visuels.value.map((p, index) => {
                if (p.value.internal.mediaType.startsWith("image")) {
                  return (
                    <Col
                      key={index}
                      md={12}
                      className="text-center project-col pl-0 pr-0"
                      style={{ paddingTop: "15px" }}
                    >
                      <motion.div
                        initial="initial"
                        animate="visible"
                        variants={{
                          initial: {
                            //width: "33.33%",
                            //margin: "0 auto",
                            //top: "5%",
                            //position: "fixed",
                            scale: 1,
                            //y: -500,
                            //opacity: 0,
                            transition: {
                              ...transition,
                              duration: 1,
                            },
                          },
                          visible: {
                            position: "relative",
                            x: 0,
                            y: 0,
                            left: 0,
                            scale: 1,
                            width: "100%",
                            transition: {
                              transition,
                            },
                            // scale: 1,
                            // opacity: 1,
                          },
                        }}
                      >
                        {/* <Image
                            fluid
                            src={`http://cockpit.dv.fr/storage/uploads${p.value.sizes.full.path}`}
                          /> */}
                        <ProgressiveImage
                          src={`${mediaUrl}${p.sizes.full.path}`}
                          placeholder={`${mediaUrl}${p.sizes.preload.path}`}
                        >
                          {(src, load) => (
                            <Image
                              className="center-block"
                              style={{
                                //opacity: load ? 0.5 : 1,
                                width: "100%",
                                height: "auto",
                                maxWidth: "100%",
                              }}
                              fluid
                              src={src}
                            />
                          )}
                        </ProgressiveImage>
                      </motion.div>
                    </Col>
                  )
                }
                if (p.value.internal.mediaType.startsWith("video")) {
                  return (
                    <Col
                      key={index}
                      md={12}
                      className="project-col pl-0 pr-0"
                      style={{ paddingTop: "15px" }}
                    >
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        variants={{
                          hidden: {
                            scale: 0.8,
                            opacity: 0,
                          },
                          visible: {
                            scale: 1,
                            opacity: 1,
                            transition: {
                              delay: 0.4,
                            },
                          },
                        }}
                      >
                        <ReactPlayer
                          url={p.value.url}
                          width={"100%"}
                          height={"auto"}
                          controls={true}
                        />
                        {/* url=
                          {`http://cockpit.dv.fr/storage/uploads${p.value.path}`} */}
                      </motion.div>
                    </Col>
                  )
                }
              })}
          </Row>
        </motion.div>
      </div>
    </Layout>
  )
}

export default ProjectTemplate

export const pageQuery = graphql`
  query ProjectBySlug($slug: String!, $lang: String!, $client: String) {
    site {
      siteMetadata {
        title
      }
    }

    cockpitProjects(title: { slug: { eq: $slug } }, lang: { eq: $lang }) {
      title {
        value
        slug
      }
      subtitle {
        value
      }
      histoire {
        value
      }
      equipe {
        value
      }
      realisation {
        value
      }
      client {
        value {
          display
        }
      }
      type {
        value {
          _id
        }
      }
      visuels {
        value {
          sizes {
            medium {
              path
            }
            preload {
              path
            }
            full {
              path
            }
          }
          value {
            internal {
              mediaType
            }
            url
          }
        }
      }
    }
    allCockpitType(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          id
          type {
            value
          }
        }
      }
    }

    cockpitClient(cockpitId: { eq: $client }) {
      nom {
        value
      }
    }
  }
`
